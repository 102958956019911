import { render, staticRenderFns } from "./Inquiries.vue?vue&type=template&id=54fe8f82&scoped=true&"
import script from "./Inquiries.vue?vue&type=script&lang=js&"
export * from "./Inquiries.vue?vue&type=script&lang=js&"
import style0 from "./Inquiries.vue?vue&type=style&index=0&id=54fe8f82&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54fe8f82",
  null
  
)

export default component.exports